import { getFeatureFlagFromLocalStorage } from '@/store/modules/feature-flags/utils';

import { SelectedOverUnder } from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { Boost, PickEmSlipType } from '@/interfaces/pick-em';
import { MIN_FORCED_INSURANCE_PICKS, UD_PICK_EM } from '@/utilities/constants';

/**
 * Check if slip has any boosts, if yes return boost type
 * Boost type will be set to the slipType
 */
export const getBoostType = ({
  selectedOverUnders,
}: {
  selectedOverUnders: SelectedOverUnder[];
}): Boost['boostType'] => {
  if (!selectedOverUnders) return null;

  const boostedSelectedOverUnder =
    selectedOverUnders?.filter((sOU) => sOU?.boost?.boostType)[0] ?? null;

  return boostedSelectedOverUnder ? boostedSelectedOverUnder.boost.boostType : null;
};

/**
 * this lets us adjust the slipType of the selected PickEmEntrySlip based on
 * the pickEmInsurance feature flag and the localStorage slipType, so we can
 * keep the slipType up to date with every change to the slip
 */
export const adjustSlipType = ({
  boostType,
  currentSlipType,
  selectionCount,
}: {
  boostType: Boost['boostType'];
  currentSlipType: PickEmSlipType;
  selectionCount: number;
}): PickEmSlipType => {
  const insuranceFeatureFlag = getFeatureFlagFromLocalStorage('pickEmInsurance');
  const isWebPick8Enabled = getFeatureFlagFromLocalStorage('webPick8');
  const localStoragePickEm = JSON.parse(localStorage.getItem(UD_PICK_EM));

  if (
    insuranceFeatureFlag &&
    currentSlipType !== 'insurance' &&
    selectionCount >= MIN_FORCED_INSURANCE_PICKS &&
    isWebPick8Enabled
  ) {
    // if state.selected.slipType is default but selection count is above the minimum for a forced flex slip
    // change the slip type to insurance
    return 'insurance';
  }

  if (!isWebPick8Enabled && boostType) {
    return boostType;
  }

  if (!insuranceFeatureFlag && localStoragePickEm?.slipType === 'insurance') {
    // if pick'em insurance is disabled by feature flag, slipType cannot be 'insurance'
    return 'default';
  }

  // local storage might indicate boosted slip type, but boost was removed by the admin
  return localStoragePickEm?.slipType === 'insurance' ? 'insurance' : 'default';
};
