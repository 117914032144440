import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { RootState } from '@/store';

import { TokenAddedToEntryProperties } from '@/ampli';
import PromoCardAirdrop from '@/components/molecules/promo-cards/components/promo-card-airdrop';
import PromoCardBoost from '@/components/molecules/promo-cards/components/promo-card-boost';
import PromoCardNoVultures from '@/components/molecules/promo-cards/components/promo-card-no-vultures';
import PromoCardPowerUp from '@/components/molecules/promo-cards/components/promo-card-power-up';
import PromoCardReferral from '@/components/molecules/promo-cards/components/promo-card-referral';
import { PowerUpType } from '@/interfaces/power-ups';
import { SportId } from '@/interfaces/types';

// Web only supports the power up types in this array
const WEB_SUPPORTED_POWER_UP_TYPES: PowerUpType[] = ['boost', 'noSweat', 'special'];

type PromoAndExpiresAt = { expiresAt: string; el: JSX.Element };

const useGetPromoCards = ({
  filterBySport,
  handleCloseModal,
  location,
  sportId,
}: {
  filterBySport?: boolean;
  handleCloseModal?: () => void;
  location: TokenAddedToEntryProperties['location'];
  sportId?: SportId;
}): JSX.Element[] => {
  const airdrops = useSelector((state: RootState) => state.pickEmOverUnder.featuredLobby.airdrops);
  const constructedAppearances = useSelector(
    (state: RootState) => state.pickEmOverUnder.featuredLobby.constructedAppearances
  );
  const constructedPromoAppearances = useSelector(
    (state: RootState) => state.pickEmOverUnder.featuredLobby.constructedPromoAppearances
  );
  const powerUps = useSelector((state: RootState) => state.pickEmOverUnder.featuredLobby.powerUps);
  const webAirdropsFeatureEnabled = useSelector(
    (state: RootState) => state.featureFlags.webAirdrops
  );
  const webNoVulturesFeatureEnabled = useSelector(
    (state: RootState) => state.featureFlags.webNoVultures
  );
  const webPowerUpsFeatureEnabled = useSelector(
    (state: RootState) => state.featureFlags.webPowerUps
  );

  const promoCardEls: JSX.Element[] = [];

  // Currently we need to sort each group by expiresAt, when the API updates
  // we can remove the double loop and should be able to simply iterate over each list.
  // The order of these cards is defined by product - FGAMES-1764

  const sortByExpiresAt = <T extends PromoAndExpiresAt>(a: T, b: T): number => {
    const currDate = dayjs(a.expiresAt);
    const prevDate = dayjs(b.expiresAt);

    if (currDate === prevDate) return 0;
    if (currDate.isBefore(prevDate)) return -1;
    return 1;
  };

  // Boosts
  // show sport-specific boosts in sport lobbies and all boosts on homepage
  let filteredPromoAppearances = constructedPromoAppearances;
  if (filterBySport) {
    filteredPromoAppearances = constructedPromoAppearances.filter(
      (cPA) => cPA.sport.id.toLowerCase() === sportId.toLowerCase() || sportId === 'home'
    );
  }

  if (filteredPromoAppearances?.length) {
    const promoCardsWithExpiration: PromoAndExpiresAt[] = [];
    filteredPromoAppearances.forEach((cPA) => {
      const cA = constructedAppearances[cPA.id];
      const gameScheduledAt = cA?.match?.scheduledAt ?? cA?.soloGame?.scheduledAt;
      const countdownExpiresAt = cA?.boost?.expiredAt ?? cA?.expiresAt ?? gameScheduledAt;
      const countdownIsExpired = countdownExpiresAt && dayjs(countdownExpiresAt).isBefore(dayjs());

      cPA.boostedLineIds.forEach((boostedLineId: string) => {
        promoCardsWithExpiration.push({
          expiresAt: countdownExpiresAt,
          el: (
            <PromoCardBoost
              key={boostedLineId}
              boostedLineId={boostedLineId}
              constructedAppearance={cA}
              countdownExpiresAt={!countdownIsExpired ? countdownExpiresAt : null}
              gameScheduledAt={gameScheduledAt}
            />
          ),
        });
      });
    });
    const sortedBoostPromos = promoCardsWithExpiration.sort(sortByExpiresAt);
    sortedBoostPromos.forEach((card) => promoCardEls.push(card.el));
  }

  // Airdrops
  if (airdrops?.length && webAirdropsFeatureEnabled) {
    const promoCardsWithExpiration: PromoAndExpiresAt[] = [];
    airdrops.forEach((airdrop) => {
      promoCardsWithExpiration.push({
        el: <PromoCardAirdrop key={airdrop.id} airdrop={airdrop} />,
        expiresAt: airdrop.expiresAt,
      });
    });
    const sortedAirdrops = promoCardsWithExpiration.sort(sortByExpiresAt);
    sortedAirdrops.forEach((card) => promoCardEls.push(card.el));
  }

  // Tokens
  if (powerUps?.length && webPowerUpsFeatureEnabled) {
    const filteredPowerUps = powerUps.filter((pU) =>
      WEB_SUPPORTED_POWER_UP_TYPES.includes(pU.type)
    );
    const promoCardsWithExpiration: PromoAndExpiresAt[] = [];
    filteredPowerUps.forEach((pU) => {
      promoCardsWithExpiration.push({
        expiresAt: pU.expiresAt,
        el: <PromoCardPowerUp key={pU.id} location={location} powerUp={pU} />,
      });
    });
    const sortedPowerUps = promoCardsWithExpiration.sort(sortByExpiresAt);
    sortedPowerUps.forEach((card) => promoCardEls.push(card.el));
  }

  if (promoCardEls.length || webNoVulturesFeatureEnabled) {
    // No vultures
    if (webNoVulturesFeatureEnabled) {
      promoCardEls.push(<PromoCardNoVultures key="noVultures" />);
    }

    // Refer-a-friend
    promoCardEls.push(<PromoCardReferral key="referral" handleCloseModal={handleCloseModal} />);
    return promoCardEls;
  }

  return [];
};

export default useGetPromoCards;
