import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import Pusher, { Channel } from 'pusher-js';

import { RootState } from '@/store';

import errorLogger from '@/utilities/errors/logger';

import { auth0Service } from '../auth/auth0-service';

const PusherEventContext = React.createContext<Pusher | null>(null);

interface PusherEventProviderProps {
  children: JSX.Element;
  isSecondaryPusherEnvFlagEnabled: boolean;
  isTertiaryPusherEnvFlagEnabled: boolean;
}

interface PusherEnvType {
  secondary: boolean;
  tertiary: boolean;
}
interface UseChannelParamType {
  pusherEnvs: PusherEnvType;
  channelName: string;
  userId?: string;
}

const API_ENDPOINT = `${process.env.API_ENDPOINT}/v1` || 'http://localhost:3000/api/v1';

let pusher: Pusher;

// initPusher is a func rather than straight calling `new`, because we want
// to wait and make sure we have the auth session (if you login or register
// we don't have this)
export const initPusher = (pusherEnvs: PusherEnvType) => {
  const { pusherAppKey, pusherAppCluster } = getPusherKeys(
    pusherEnvs.secondary,
    pusherEnvs.tertiary
  );
  if (pusher) {
    // re-authorize pusher instance (pusher instance exists until tab refresh or tab close)
    pusher.disconnect();
    pusher = null;
  }
  if (!pusher) {
    pusher = new Pusher(pusherAppKey, {
      cluster: pusherAppCluster,
      channelAuthorization: {
        endpoint: `${API_ENDPOINT}/authorizations/pusher_channels`,
        transport: 'ajax',
        headers: {
          Authorization: Cookies.get('session') || auth0Service.getAccessToken(),
        },
      },
    });
  }

  return pusher;
};

/*
We may use 1 of 3 Pusher environments available to us. Which environment we connect
to will depend on the feature flags that we receive from the BE. We have separate
APP_KEYS and APP_CLUSTERS in our env files so we can pull in the ones we need based
on the feature flags we've received.

Note: The BE initializes their pusher client based on that flag being true, so for
instance, if we connect to the secondaryEnv when that flag is NOT true, we wouldn’t
receive any pusher updates in the app.

PS: The order of checks is important here:
tertiary >> secondary >> default.
*/
const getPusherKeys = (
  isSecondaryPusherEnvFlagEnabled: boolean,
  isTertiaryPusherEnvFlagEnabled: boolean
) => {
  let pusherAppKey;
  let pusherAppCluster;
  if (isTertiaryPusherEnvFlagEnabled) {
    pusherAppKey = process.env.PUSHER_APP_KEY_TERTIARY;
    pusherAppCluster = process.env.PUSHER_APP_CLUSTER_TERTIARY;
  } else if (isSecondaryPusherEnvFlagEnabled) {
    pusherAppKey = process.env.PUSHER_APP_KEY_SECONDARY;
    pusherAppCluster = process.env.PUSHER_APP_CLUSTER_SECONDARY;
  } else {
    pusherAppKey = process.env.PUSHER_APP_KEY;
    pusherAppCluster = process.env.PUSHER_APP_CLUSTER;
  }

  return { pusherAppKey, pusherAppCluster };
};

export type PusherService = {
  subscribeToPrivateChannels: typeof subscribeToPrivateChannels;
  unsubscribeToPrivateChannels: typeof unsubscribeToPrivateChannels;
};

export const subscribeToPrivateChannels = (userId?: string) => {
  if (!pusher.channel(`private-user-${userId}`) && userId) {
    errorLogger(false, `subscribing -- private-user-${userId}`);
    return pusher.subscribe(`private-user-${userId}`);
  }
  return pusher.channel(`private-user-${userId}`);
};

export const unsubscribeToPrivateChannels = (userId: string) => {
  pusher.unsubscribe(`private-user-${userId}`);
};

export const useChannel = ({ pusherEnvs, channelName, userId }: UseChannelParamType) => {
  if (!pusher) {
    initPusher(pusherEnvs);
  }
  // this is annoying but sometimes, useChannel is called before private channels are subscribed
  subscribeToPrivateChannels(userId);
  const channel = useMemo(() => {
    if (!channelName) return null;
    if (!pusher.channel(channelName)) {
      errorLogger(false, `subscribing -- ${channelName}`);
      return pusher.subscribe(channelName);
    }
    // if we already have the channel we still want to return it
    // might just be able to subscribe to it again
    return pusher.channel(channelName);
  }, [channelName]);

  return channel;
};

export const unsubscribeChannel = (channelName: string) => {
  if (pusher.channel(channelName)) {
    errorLogger(false, `unsubscribing -- ${channelName}`);
    pusher.unsubscribe(channelName);
  }
};

// TODO [FAN-2199]: this service needs so much cleaning up, see ActiveDraftCell
export const useEvent = (channel: Channel, event: string, callback: (data: any) => void) => {
  const memoCallback = useCallback(callback, [callback]);
  useEffect(() => {
    if (channel) {
      channel.unbind(event, memoCallback); // only bind the event once
      channel.bind(event, memoCallback);
    }
    return () => {
      if (channel) {
        channel.unbind(event, memoCallback);
      }
    };
  }, [memoCallback, channel, event]);
};

export interface EventItem {
  channelName: string;
  eventName: string;
  callback: (...args: any) => void;
}

const PusherEventProviderActual = (props: PusherEventProviderProps) => {
  const { isSecondaryPusherEnvFlagEnabled, isTertiaryPusherEnvFlagEnabled } = props;
  const providerPusher = useMemo(() => {
    const { pusherAppKey, pusherAppCluster } = getPusherKeys(
      isSecondaryPusherEnvFlagEnabled,
      isTertiaryPusherEnvFlagEnabled
    );
    return new Pusher(pusherAppKey, {
      cluster: pusherAppCluster,
      authEndpoint: `${API_ENDPOINT}/authorizations/pusher_channels`,
      auth: {
        params: null,
        headers: {
          // this will be either Devise or Auth0 - only one token is present at a time
          // pusher doesn't accept an async function, so we can't call auth0Service.getAuth,
          // but pusher will only initalize after a token exists
          Authorization: Cookies.get('session') || auth0Service.getAccessToken(),
        },
      },
    });
  }, [isSecondaryPusherEnvFlagEnabled, isTertiaryPusherEnvFlagEnabled]);

  return (
    <PusherEventContext.Provider value={providerPusher}>
      {props.children}
    </PusherEventContext.Provider>
  );
};

export const PusherEventProvider = connect((state: RootState) => ({
  isSecondaryPusherEnvFlagEnabled: state.featureFlags.secondaryPusherEnv,
  isTertiaryPusherEnvFlagEnabled: state.featureFlags.tertiaryPusherEnv,
}))(PusherEventProviderActual);

export default PusherEventContext;
