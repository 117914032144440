import React, { useEffect, useMemo, useRef } from 'react';
import { connect, useSelector } from 'react-redux';

import { RootState } from '@/store';
import {
  getAppearanceStats as getAppearanceStatsAction,
  getAppearanceStatsById as getAppearanceStatsByIdAction,
} from '@/store/modules/appearance-stats/actions';

import { ampli } from '@/ampli';
import Avatar from '@/components/atoms/avatar';
import Icon from '@/components/atoms/icon';
import Loader from '@/components/atoms/loader';
import NewsTile from '@/components/molecules/news-tile';
import OverUnderList from '@/components/molecules/over-under-list';
/* eslint-disable import/no-cycle */
import OverUnderLiveResultCell from '@/components/molecules/pick-em-player-cells/over-under/over-under-live-result-cell';
import RivalLineCell from '@/components/molecules/pick-em-player-cells/rival-lines/rival-line-cell';
import RivalLiveResultCell from '@/components/molecules/pick-em-player-cells/rival-lines/rival-live-result-cell';
import StatTable from '@/components/molecules/stat-table';
import {
  AppearanceStats,
  AppearanceStatsByIdRequest,
  AppearanceStatsRequest,
} from '@/interfaces/appearance-stats';
import {
  ConstructedEntrySlipPickEmOverUnderAppearance,
  ConstructedPickEmOverUnderLineAppearance,
  ConstructedPickEmOverUnderLineAppearances,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
/* eslint-enable import/no-cycle */
import {
  ConstructedEntrySlipPickEmRivalAppearance,
  ConstructedPickEmRivalLine,
  ConstructedPickEmRivalLineAppearance,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import { Teams } from '@/interfaces/drafting-config';
import { Boost, PickLocation, SoloGame } from '@/interfaces/pick-em';
import { SportId } from '@/interfaces/types';
import useTeamColors from '@/utilities/hooks/use-team-colors';
import langHelper from '@/utilities/lang-helper';

import FavoriteButton from '../favorite-button';

import styles from './styles.scss';

export type PickEmPlayerModalProps = PickEmPlayerModalPropsDirect & PickEmPlayerModalPropsComputed;

/**
 * These props are passed directly to the component from declaring code.
 */
export type PickEmPlayerModalPropsDirect = {
  constructedPickEmRivalLine?: ConstructedPickEmRivalLine;
  constructedAppearance:
    | ConstructedPickEmRivalLineAppearance
    | ConstructedPickEmOverUnderLineAppearance
    | ConstructedEntrySlipPickEmOverUnderAppearance
    | ConstructedEntrySlipPickEmRivalAppearance;
  handleCloseModal: () => void;
  isResults?: boolean; // only used on results
  boost?: Boost; // only used on live/results
  sportId?: SportId;
};

/**
 * These props are computed from the Redux store and passed to the component.
 */
export type PickEmPlayerModalPropsComputed = {
  teams: Teams;
  appearanceStats: AppearanceStats;
  getAppearanceStatsById: ({ appearanceId }: AppearanceStatsByIdRequest) => void;
  getAppearanceStats: ({ scoringTypeId, appearanceId }: AppearanceStatsRequest) => void;
  constructedOverUnderLineAppearances: ConstructedPickEmOverUnderLineAppearances;
  constructedRivalLines: ConstructedPickEmRivalLine[];
};

const PickEmPlayerModal = (props: PickEmPlayerModalProps) => {
  const {
    constructedPickEmRivalLine,
    constructedAppearance,
    handleCloseModal,
    getAppearanceStatsById,
    getAppearanceStats,
    appearanceStats,
    teams,
    isResults,
    constructedOverUnderLineAppearances,
    constructedRivalLines,
    boost,
    sportId,
  } = props;
  const [primaryColor, secondaryColor] = useTeamColors({
    team: constructedAppearance?.team,
    sport: constructedAppearance?.sport,
  });
  const buttonRef = useRef(null);
  let soloGame: SoloGame;
  if (constructedAppearance && 'soloGame' in constructedAppearance) {
    soloGame = constructedAppearance?.soloGame;
  }

  const playerRivalLines = useMemo(
    () =>
      constructedRivalLines?.filter((cRLA: ConstructedPickEmRivalLine) =>
        cRLA.appearanceOptions.find((appOption) => appOption.id === constructedAppearance?.id)
      ),
    [constructedAppearance, constructedRivalLines]
  );

  const cA = useSelector((state: RootState) => {
    if (sportId === 'home') {
      return state.pickEmOverUnder.featuredLobby.constructedAppearances[constructedAppearance.id];
    }
    return constructedOverUnderLineAppearances
      ? constructedOverUnderLineAppearances[constructedAppearance.id]
      : undefined;
  });

  const getScoringTypeId = () => {
    let scoringTypeId: string = null;

    scoringTypeId = cA?.scoringTypeId;

    if (!scoringTypeId) {
      scoringTypeId = playerRivalLines?.find((line) => line?.rival?.scoringTypeId)?.rival
        ?.scoringTypeId;
    }

    return scoringTypeId;
  };

  const scoringTypeId = getScoringTypeId();
  const isManualSport = cA?.sport?.manual;

  useEffect(() => {
    buttonRef.current.focus();

    const { player } = constructedAppearance;
    ampli.pickemPlayerCardScreenOpened({
      player_id: player.id,
      player_name: langHelper.getPlayerFullName(player),
      sport_name: player.sportId,
    });
    /** We only want this amplitude event to be called once when this modal loads.
     * constructedAppearance may change, but we do not want those changes to trigger
     * the event again.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!appearanceStats[constructedAppearance.id] && !isManualSport) {
      // don't fetch stats for manual sports
      if (scoringTypeId) {
        // use scoringTypeId to get TOT stats
        getAppearanceStats({
          scoringTypeId,
          appearanceId: constructedAppearance.id,
        });
      } else {
        getAppearanceStatsById({ appearanceId: constructedAppearance.id });
      }
    }
  }, [
    appearanceStats,
    constructedAppearance.id,
    getAppearanceStats,
    getAppearanceStatsById,
    isManualSport,
    playerRivalLines,
    scoringTypeId,
  ]);

  const appearanceStat = appearanceStats && appearanceStats[constructedAppearance.id];

  const pickEmLinesToShow: {
    overUnderList: JSX.Element;
    rivals: JSX.Element[];
  } = {
    overUnderList: null,
    rivals: [],
  };

  if (!isResults) {
    // show all active lines on Pick Em page modals
    if (!pickEmLinesToShow.rivals.length) {
      pickEmLinesToShow.rivals = playerRivalLines?.map((rivalLine) => (
        <RivalLineCell
          key={rivalLine.id}
          constructedPickEmRivalLine={rivalLine}
          pickLocation={PickLocation.PLAYER_CARD}
        />
      ));
    }

    // checking if constructedAppearance has any overUnderLines to show
    if (!pickEmLinesToShow.overUnderList && cA?.appearanceLines) {
      pickEmLinesToShow.overUnderList = (
        <OverUnderList
          key={cA?.id}
          constructedPickEmAppearance={cA}
          appearanceLines={cA.appearanceLines}
          pickLocation={PickLocation.PLAYER_CARD}
        />
      );
    }
  }

  if (isResults) {
    // only show single line on Live and Results pages modals
    if (!pickEmLinesToShow.rivals.length && 'rivalOption' in constructedAppearance) {
      pickEmLinesToShow.rivals.push(
        <RivalLiveResultCell
          key={constructedAppearance.id}
          constructedPickEmRivalLine={constructedPickEmRivalLine}
        />
      );
    }
    if (!pickEmLinesToShow.overUnderList && 'selectedOverUnderOption' in constructedAppearance) {
      pickEmLinesToShow.overUnderList = (
        <OverUnderLiveResultCell
          key={constructedAppearance.id}
          constructedPickEmAppearance={constructedAppearance}
          boost={boost}
        />
      );
    }
  }

  let statsTableEl;
  if (!appearanceStat) {
    if (cA?.sport?.manual) {
      statsTableEl = null;
    } else {
      statsTableEl = <Loader />;
    }
  } else {
    const { headers, rows } = appearanceStat.statsTable;
    if (headers.length && rows.length) {
      statsTableEl = (
        <>
          <p className={styles.statsHeading}>Past performances</p>
          <StatTable statsTable={appearanceStat.statsTable} />
        </>
      );
    }
  }

  const numberOfActiveOverUnderLines = cA?.appearanceLines?.length ?? 0;
  const numberOfActiveRivalLines = playerRivalLines?.length ?? 0;
  const totalActiveLinesForPlayer = numberOfActiveOverUnderLines + numberOfActiveRivalLines;

  return (
    <div className={styles.pickEmPlayerModal} data-testid="pickem-player-modal-container">
      <div className={styles.scrollingSection}>
        <div className={styles.buttonRow}>
          {!isResults && (
            <FavoriteButton
              player={constructedAppearance.player}
              appearanceId={constructedAppearance.id}
              numberOfActiveLines={totalActiveLinesForPlayer}
              iconClassName={styles.favoriteIcon}
            />
          )}
          <button
            className={styles.closeButton}
            ref={buttonRef}
            onClick={handleCloseModal}
            data-testid="pickem-player-modal-close-button"
            aria-label="Close"
          >
            <Icon name="clear" className={styles.clearIcon} />
          </button>
        </div>
        <div className={styles.topSection}>
          <div
            className={styles.primaryCircle}
            style={{
              borderColor: secondaryColor,
              backgroundColor: primaryColor,
            }}
          >
            <Avatar
              url={constructedAppearance.player.imageUrl}
              classNames={{
                avatar: styles.avatar,
              }}
              customPlaceholder="placeholderPlayer"
            />
          </div>
        </div>
        <div className={styles.playerNameSection}>
          <p className={styles.playerName}>
            {langHelper.getPlayerFullName(constructedAppearance.player)}
          </p>
          {constructedAppearance.team && (
            <div className={styles.teamRow}>
              <Icon className={styles.sportIcon} name={constructedAppearance.sport.icon} />
              <p className={styles.teamName}>{constructedAppearance.team.name}</p>
            </div>
          )}
          {!constructedAppearance.team && constructedAppearance.player.country && (
            <div className={styles.teamRow}>
              <Icon className={styles.sportIcon} name={constructedAppearance.sport.icon} />
              <p className={styles.teamName}>{constructedAppearance.player.country}</p>
            </div>
          )}
          <div className={styles.matchInfo}>
            <p className={styles.matchInfoText}>
              {langHelper.genericMatchTextPickem({
                constructedAppearance,
                teams,
                soloGame,
              })}
              {langHelper.timeToDayOrDate(
                cA?.match?.scheduledAt ||
                  soloGame?.scheduledAt ||
                  constructedAppearance?.match?.scheduledAt
              )}
            </p>
          </div>
        </div>
        {pickEmLinesToShow.overUnderList && (
          <div className={styles.tileSection}>
            <p className={styles.tileTitle}>Higher/Lower</p>
            {pickEmLinesToShow.overUnderList}
          </div>
        )}
        {pickEmLinesToShow.rivals.length ? (
          <div className={styles.tileSection} data-testid="pickem-player-lines">
            <p className={styles.tileTitle}>Rivals</p>
            {pickEmLinesToShow.rivals}
          </div>
        ) : null}
        <div className={styles.statSection}>{statsTableEl}</div>
        <div className={styles.newsSection}>
          {appearanceStat?.latestNewsItem && (
            <>
              <p className={styles.newsHeading}>News</p>
              <NewsTile newsItem={appearanceStat.latestNewsItem} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state: RootState) => ({
    appearanceStats: state.appearanceStats,
    teams: state.draftingConfig.teams,
    constructedOverUnderLineAppearances: state.pickEmOverUnder.constructedAppearances,
    constructedRivalLines: state.pickEmRivals.constructedPickEmRivals,
  }),
  (dispatch) => ({
    getAppearanceStatsById: ({ appearanceId }: AppearanceStatsByIdRequest) =>
      dispatch(getAppearanceStatsByIdAction({ appearanceId })),
    getAppearanceStats: ({ appearanceId, scoringTypeId }: AppearanceStatsRequest) =>
      dispatch(getAppearanceStatsAction({ appearanceId, scoringTypeId })),
  })
)(PickEmPlayerModal);
