import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import Cookies from 'js-cookie';

import { RootState } from '@/store';
import {
  acceptOverUnderLineSwap as acceptOverUnderLineSwapAction,
  acceptSelectedOverUnderLineSwap as acceptSelectedOverUnderLineSwapAction,
  setPowerUpOnSelectedOverUnder as setPowerUpOnSelectedOverUnderAction,
  togglePickEmInsurance as togglePickEmInsuranceAction,
  UpdateSelectedArgs,
  updateSelectedOverUnders as updateSelectedOverUndersAction,
} from '@/store/modules/pick-em/actions';

import { TokenAppliedToSelectionProperties } from '@/ampli';
import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import PickEmButton from '@/components/atoms/pick-em-button';
import { useToast } from '@/components/atoms/toast';
import FadeAnimation from '@/components/molecules/animations/fade';
import TextSlideUpAndFadeAnimation from '@/components/molecules/animations/text-slide-up-and-fade';
import ModalWrapper from '@/components/molecules/modal-wrapper';
import {
  ConstructedPickEmOverUnderLineAppearance,
  SelectedOverUnder,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { FeatureFlags } from '@/interfaces/feature-flags';
import { OverUnderLine, OverUnderOption, PickEmSlipType, PickLocation } from '@/interfaces/pick-em';
import { RedeemablePowerUp } from '@/interfaces/power-ups';
import { SportId } from '@/interfaces/types';
import { User } from '@/interfaces/user';
import {
  UD_MAX_PAYOUT_MULTIPLIER_MODAL_DISMISSED,
  UD_PICK_EM_SELECTED_VARIANT,
} from '@/utilities/constants';
import { areAppearanceLinesPowerUppable, getMinInsuredPicks } from '@/utilities/helpers';
import langHelper from '@/utilities/lang-helper';

import MaxPayoutMultiplierModal from '../over-under-list-cell/components/max-payout-multiplier-modal-content';

import styles from './styles.scss';

export interface OptionsWrapperProps {
  acceptOverUnderLineSwap: ({ overUnderLineIds }: { overUnderLineIds: string[] }) => void;
  acceptSelectedOverUnderLineSwap: ({ optionIds }: { optionIds: string[] }) => void;
  classNames?: {
    selectedOverride?: string;
    wrapper?: string;
  };
  constructedAppearance: ConstructedPickEmOverUnderLineAppearance;
  entryPowerUp: RedeemablePowerUp;
  isPickEmFeatureEnabled: FeatureFlags['pickEm'];
  isWebPick8Enabled: FeatureFlags['webPick8'];
  optionChoice?: 'higher' | 'lower' | null;
  overUnderLine: OverUnderLine;
  pickLocation: PickLocation;
  selectedOptionId?: string;
  selectedOverUnders: SelectedOverUnder[];
  setPowerUpOnSelectedOverUnder: ({
    action,
    location,
    overUnderLineId,
  }: {
    action: 'add' | 'remove';
    location: TokenAppliedToSelectionProperties['location'];
    overUnderLineId: string;
  }) => void;
  slipType: PickEmSlipType;
  swapLineOptionAndAnimate?: boolean;
  swapStatValueAndAnimate?: boolean;
  togglePickEmInsurance: ({
    slipType,
    minInsuredPicks,
  }: {
    slipType: PickEmSlipType;
    minInsuredPicks: number;
  }) => void;
  updateSelectedOverUnders: ({
    action,
    selection,
    overUnderLine,
    isPickEmFeatureEnabled,
    lobbyName,
    pickLocation,
    minInsuredPicks,
    maxUserPicks,
  }: UpdateSelectedArgs<SelectedOverUnder>) => void;
  user: User;
}

type ParamTypes = {
  sportId?: SportId;
};

const OptionsWrapper = (props: OptionsWrapperProps) => {
  const {
    acceptOverUnderLineSwap,
    acceptSelectedOverUnderLineSwap,
    classNames,
    constructedAppearance,
    entryPowerUp,
    isPickEmFeatureEnabled,
    isWebPick8Enabled,
    optionChoice,
    overUnderLine,
    pickLocation,
    selectedOptionId,
    selectedOverUnders,
    setPowerUpOnSelectedOverUnder,
    slipType,
    swapLineOptionAndAnimate,
    swapStatValueAndAnimate,
    togglePickEmInsurance,
    updateSelectedOverUnders,
    user,
  } = props;
  const isRemoved = overUnderLine?.status === 'removed';
  const { sportId } = useParams<ParamTypes>();

  const openModal = useModal();
  const openToast = useToast();

  const [isAcceptButton, setIsAcceptButton] = useState<boolean>(false);
  const onlyMultiplierSwap =
    overUnderLine?.oldStatValue === overUnderLine?.statValue && overUnderLine?.oldLineOptions;
  const selectedOverUnder = selectedOverUnders?.find(
    (sOU) => sOU.overUnderLineId === overUnderLine?.id
  );

  const oULNeedsAcceptance = !!(
    overUnderLine?.needsAcceptanceForStatChange &&
    !onlyMultiplierSwap &&
    selectedOverUnder
  );

  let amplitudePickLocation: TokenAppliedToSelectionProperties['location'];
  if (pickLocation === PickLocation.REVIEW) {
    amplitudePickLocation = 'review';
  }
  if (pickLocation === PickLocation.PLAYER_CARD) {
    amplitudePickLocation = 'player card';
  }
  if (pickLocation === PickLocation.LOBBY) {
    amplitudePickLocation = 'lobby';
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (oULNeedsAcceptance) {
      const delay = setTimeout(() => {
        setIsAcceptButton(true);
      }, 0); // Delay in milliseconds before remounting original component
      return () => clearTimeout(delay);
    }
    if (!oULNeedsAcceptance) {
      setIsAcceptButton(false);
    }
  }, [oULNeedsAcceptance]);

  const handleOptionClick = ({
    option,
    isRemoved: optionIsRemoved,
  }: {
    option: OverUnderOption;
    isRemoved: boolean;
  }) => {
    const selection: SelectedOverUnder = {
      overUnderLineId: option.overUnderLineId,
      option,
      boost: overUnderLine.overUnder.boost,
      powerUpId: null,
      constructedOverUnderAppearance: {
        ...constructedAppearance,
        appearanceLines: [
          {
            id: option.overUnderLineId,
            splitLine: overUnderLine.splitLine,
            option: option.choice as 'higher' | 'lower' | null,
          },
        ],
      },
      overUnderLineInfo: {
        displayStat: overUnderLine.overUnder.appearanceStat.displayStat,
        statValue: overUnderLine.statValue,
        appearanceStat: overUnderLine.overUnder.appearanceStat,
        status: overUnderLine.status,
        liveEvent: overUnderLine.liveEvent,
      },
    };

    const action = selectedOptionId === option.id ? 'remove' : 'add';

    const hasExceededPayoutMultiplier =
      localStorage.getItem('max_payout_modifier_reached') === 'default' ||
      localStorage.getItem('max_payout_modifier_reached') === 'insurance';

    const hasDismissedMaxPayoutMessage = Cookies.get(UD_MAX_PAYOUT_MULTIPLIER_MODAL_DISMISSED);

    const updateSOUs = () => {
      const selectionHasBoost = selection.constructedOverUnderAppearance.boost;
      const boostType = selectionHasBoost?.boostType;

      const minInsuredPicks: number = getMinInsuredPicks(
        user.stateConfig?.pickEm.minimumSelectionSize
      );

      const label: string = langHelper.formatPickemBoostLabel(boostType);

      if (!isWebPick8Enabled && selectionHasBoost && slipType === 'insurance') {
        openToast({
          message: `Your payout was changed to Standard. Entries with a ${label} cannot be flexed.`,
          open: true,
        });
        localStorage.setItem(UD_PICK_EM_SELECTED_VARIANT, 'default');
        togglePickEmInsurance({
          slipType: 'default',
          minInsuredPicks,
        });
      }

      updateSelectedOverUnders({
        action: optionIsRemoved ? null : action,
        selection,
        overUnderLine,
        isPickEmFeatureEnabled,
        lobbyName: sportId,
        pickLocation,
        minInsuredPicks,
        maxUserPicks: user.stateConfig.pickEm.maximumSelectionSize,
      });
    };

    const isSamePlayerLine = selectedOverUnders.some(
      (sOU) => sOU.constructedOverUnderAppearance.id === selection.constructedOverUnderAppearance.id
    );
    if (
      option.payoutMultiplier !== 1 &&
      action === 'add' &&
      hasExceededPayoutMultiplier &&
      !isSamePlayerLine &&
      hasDismissedMaxPayoutMessage !== 'true'
    ) {
      openModal(({ handleCloseModal: close }: HandleCloseModal) => (
        <ModalWrapper handleCloseModal={close} hideCloseButton>
          <MaxPayoutMultiplierModal
            handleCloseModal={close}
            updateSelectedOverUnders={updateSOUs}
          />
        </ModalWrapper>
      ));
    } else {
      updateSOUs();
    }
  };

  const acceptSwapButton = (
    <div className={styles.optionsContainer}>
      <PickEmButton
        className={styles.acceptButton}
        optionText="Accept"
        onClick={(e) => {
          e.stopPropagation();
          acceptOverUnderLineSwap({ overUnderLineIds: [overUnderLine.id] });
          acceptSelectedOverUnderLineSwap({ optionIds: [selectedOptionId] });
        }}
        selected={false}
      />
    </div>
  );

  const powerUpButton = (
    <div className={styles.optionsContainer}>
      <PickEmButton
        className={clsx(styles.acceptButton, {
          [styles.remove]: selectedOverUnder?.powerUpId === entryPowerUp?.id,
          [styles.playerCard]: pickLocation === PickLocation.PLAYER_CARD,
        })}
        optionText={
          entryPowerUp?.id === selectedOverUnder?.powerUpId ? 'Remove token' : 'Apply token'
        }
        onClick={(e) => {
          e.stopPropagation();
          setPowerUpOnSelectedOverUnder({
            action: entryPowerUp?.id === selectedOverUnder?.powerUpId ? 'remove' : 'add',
            overUnderLineId: overUnderLine.id,
            location: amplitudePickLocation,
          });
        }}
        selected={false}
      />
    </div>
  );

  const defaultButtons = (
    <div className={styles.optionsContainer}>
      {overUnderLine?.options?.map((option, index) => {
        if (overUnderLine.splitLine) {
          if (pickLocation === PickLocation.REVIEW && option.id !== selectedOptionId) {
            return null; // show selected side in review section
          }
          if (pickLocation !== PickLocation.REVIEW && option.choice !== optionChoice) {
            return null; // show optionChoice side when not in review section
          }
        }

        let payoutMultiplier;
        let optionText;

        if (overUnderLine.oldLineOptions && overUnderLine.oldLineOptions?.[index]) {
          payoutMultiplier = overUnderLine.oldLineOptions[index].payoutMultiplier;
          optionText = overUnderLine.oldLineOptions[index].choiceDisplay;
        } else {
          payoutMultiplier = option.payoutMultiplier;
          optionText = option.choiceDisplay;
        }

        const currentMultiplier = (
          <span className={styles.payoutMultiplier}>{`${payoutMultiplier}x`}</span>
        );

        const updatedMultiplier = (
          <span className={styles.payoutMultiplier}>{`${option.payoutMultiplier}x`}</span>
        );

        const lineOptionText =
          option.payoutMultiplier !== 1 ? (
            <div className={styles.lineOption}>
              <span className={styles.optionText}>{optionText}</span>
              <span className={styles.payoutMultiplierWrapper}>
                <TextSlideUpAndFadeAnimation
                  swapCondition={swapLineOptionAndAnimate}
                  secondarySwapCondition={swapStatValueAndAnimate}
                  incomingContent={updatedMultiplier}
                  defaultContent={currentMultiplier}
                />
              </span>
            </div>
          ) : (
            option.choiceDisplay
          );

        return (
          <PickEmButton
            key={option.id}
            optionText={lineOptionText}
            className={clsx({
              [styles.playerModalPickEmButton]: pickLocation === PickLocation.PLAYER_CARD,
              [styles.selected]: option.id === selectedOptionId,
              [classNames?.selectedOverride]: option.id === selectedOptionId,
            })}
            onClick={(e) => {
              e.stopPropagation();
              handleOptionClick({
                option,
                isRemoved,
              });
            }}
            selected={option.id === selectedOptionId}
          />
        );
      })}
    </div>
  );

  // Show apply/remove token button on in review section projections if token is selection level
  const showApplyTokenButton =
    pickLocation === PickLocation.REVIEW &&
    areAppearanceLinesPowerUppable({
      overUnderLines: [overUnderLine],
      restrictions: entryPowerUp?.restrictions,
      selectedOverUnders,
    });
  const showRemoveTokenButton = entryPowerUp && selectedOverUnder?.powerUpId === entryPowerUp?.id;

  return (
    <div className={classNames?.wrapper}>
      <FadeAnimation
        swapCondition={isAcceptButton}
        defaultContent={
          showApplyTokenButton || showRemoveTokenButton ? powerUpButton : defaultButtons
        }
        incomingContent={acceptSwapButton}
      />
    </div>
  );
};

export default connect(
  (state: RootState) => ({
    isPickEmFeatureEnabled: state.featureFlags.pickEm,
    isWebPick8Enabled: state.featureFlags.webPick8,
    entryPowerUp: state.pickEmEntries.selected.powerUp,
    selectedOverUnders: state.pickEmEntries.selected.selectedOverUnders,
    slipType: state.pickEmEntries.selected.slipType,
    user: state.user,
  }),
  (dispatch) => ({
    updateSelectedOverUnders: ({
      action,
      selection,
      overUnderLine,
      isPickEmFeatureEnabled,
      lobbyName,
      pickLocation,
      minInsuredPicks,
      maxUserPicks,
    }: UpdateSelectedArgs<SelectedOverUnder>) =>
      dispatch(
        updateSelectedOverUndersAction({
          action,
          selection,
          overUnderLine,
          isPickEmFeatureEnabled,
          lobbyName,
          pickLocation,
          minInsuredPicks,
          maxUserPicks,
        })
      ),
    togglePickEmInsurance: ({
      slipType,
      minInsuredPicks,
    }: {
      slipType: PickEmSlipType;
      minInsuredPicks: number;
    }) => dispatch(togglePickEmInsuranceAction({ slipType, minInsuredPicks })),
    acceptSelectedOverUnderLineSwap: ({ optionIds }: { optionIds: string[] }) =>
      dispatch(acceptSelectedOverUnderLineSwapAction({ optionIds })),
    acceptOverUnderLineSwap: ({ overUnderLineIds }: { overUnderLineIds: string[] }) =>
      dispatch(acceptOverUnderLineSwapAction({ overUnderLineIds })),
    setPowerUpOnSelectedOverUnder: ({
      action,
      location,
      overUnderLineId,
    }: {
      action: 'add' | 'remove';
      location: TokenAppliedToSelectionProperties['location'];
      overUnderLineId: string;
    }) => dispatch(setPowerUpOnSelectedOverUnderAction({ action, location, overUnderLineId })),
  })
)(OptionsWrapper);
