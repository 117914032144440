/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import { IconTypes } from '@/components/atoms/icon';

import {
  ConstructedPickEmOverUnderLineAppearance,
  ConstructedPickEmOverUnderLineAppearances,
  ConstructedPromoAppearance,
  SelectedOverUnder,
} from '../constructed-interfaces/constructed-pick-em-over-under-appearance';
import {
  ConstructedPickEmRivalLine,
  SelectedRival,
} from '../constructed-interfaces/constructed-pick-em-rival-appearance';
import {
  Appearance,
  AppearanceResponse,
  AppearancesResponse,
  LiveStatLines,
  Player,
  PlayerResponse,
  PlayersResponse,
} from '../constructed-interfaces/constructed-slates';
import { NotCurrentUserResponse, User } from '../drafts';
import { ApiErrorDataResponse, AppErrorResponse } from '../error';
import { Match, Matches, MatchesResponse, MatchResponse } from '../matches';
import {
  AppliedPowerUp,
  AppliedPowerUpResponse,
  RedeemablePowerUp,
  RedeemablePowerUpResponse,
} from '../power-ups';
import { MatchStatus, PickEmResult, SportId } from '../types';
import { UserBadge, UserBadgeResponse } from '../user';

/**
 * Requests
 * ***********************************************
 */

export interface EntrySlipRequest {
  payoutOptionId: string;
  pickEmPoolStyleId?: string;
  fee: string;
  powerUpId: string | null;
  options: {
    id: string;
    power_up_id: string | null;
    type: string;
  }[];
  pool?: boolean;
  multiplier: string;
  insured?: boolean;
}

/**
 * Redux
 * ***********************************************
 */

// Pick Em Lines
export interface OverUnderOption {
  id: string;
  choice: 'higher' | 'lower' | null;
  choiceDisplay: string;
  overUnderLineId: string;
  payoutMultiplier: number;
  type: 'OverUnderOption'; // probably an enum
}

export interface PayoutOutcomeRequest {
  id: string;
  type: 'OverUnderOption' | 'RivalOption';
}

export interface OverUnderOptions {
  [optionId: string]: OverUnderOption;
}

export interface PickEmAppearanceStat {
  id: string;
  appearanceId: string;
  displayStat: string;
  gradedBy: 'highScore' | 'lowScore';
  pickemStatId: string;
  stat: string; // this is probably an enum
}

export interface OverUnder {
  id: string;
  scoringTypeId: string | null;
  title: string;
  appearanceStat: PickEmAppearanceStat;
  boost: Boost | null;
  optionPriority: 'higher' | 'lower' | 'none';
}

export interface Boost {
  id: string;
  boostType: 'discount' | 'payoutBooster' | 'specialLine' | 'sweepstakes';
  color: string | null;
  comingSoonEnabled: boolean;
  contentfulInfoId: string | null;
  description: string;
  displayTitle: string;
  expiredAt: string | null;
  icon: IconTypes;
  payoutStyleId: string;
  payoutType: string;
  textColor: string;
}

export interface OverUnders {
  [overUnderId: string]: OverUnder;
}

export type OverUnderLineStatus = 'pending' | 'closed' | 'active' | 'removed' | 'suspended'; // 'removed' and 'suspended' are not api things, but web things

export interface OverUnderLine {
  id: string;
  appearanceId?: string;
  expiresAt: string | null;
  liveEvent: boolean;
  liveEventStat: string | null;
  options?: OverUnderOption[];
  overUnder?: OverUnder;
  overUnderId: string;
  rank: number;
  statValue: string;
  status: OverUnderLineStatus;
  oldOverUnderLineId?: string; // not from api
  oldStatValue?: string; // not from api
  oldLineOptions?: OverUnderOption[];
  // The property `splitLine` is not from the api. This is something we use
  // on the FE, for 2-way scorchers. The idea is that if there are 2-way scorchers,
  // we may want to display them on different rows in the UI; for this, `splitLine`
  // would be set to true). Setting this property during the construction of the
  // object saves us additional checks while rendering.
  splitLine: boolean;
  displayOption?: 'higher' | 'lower'; // added in the over under list
  needsAcceptanceForStatChange?: boolean | null; // not from api
}

export interface OverUnderLines {
  [id: string]: OverUnderLine;
}

export interface OverUnderLineSwap {
  oldId: string;
  oldStat: string;
  newId: string;
  newStat: string;
  options: OverUnderOption[];
  multiplierSwap: boolean;
  oldLineOptions: OverUnderOption[];
}

export interface SoloGame {
  id: number;
  competitionId: string | null;
  manuallyCreated: boolean;
  matchProgress: string | null;
  period: number;
  scheduledAt: string;
  score: string | null;
  sportId: SportId;
  sportTournamentRoundId: number | null;
  status: MatchStatus;
  title: string;
  type: 'SoloGame';
}

export interface SoloGames {
  [id: number]: SoloGame;
}

// TODO: remove Boost['boostType'] after webPick8 feature flag gets removed
export type PickEmSlipType = 'default' | 'insurance' | Boost['boostType'];

// Favorites, Pick'Em and Rivals
export enum PickLocation {
  FAVORITES = 'favorites',
  LOBBY = 'lobby',
  PLAYER_CARD = 'player card',
  REVIEW = 'review',
  SHARED_ENTRY = 'shared entry',
}

export type PickEmPageType = 'higher-lower' | 'rivals' | 'favorites';

export type PickEmEventTiming = 'all' | 'in-game' | 'pre-game';

export type PickEmPoolContestStatus = 'Final' | 'In progress' | 'Not started';

// Live and Settled Pick Em
export interface Selection {
  id: string;
  inPlay: boolean;
  result: PickEmResult;
  optionId: string;
  optionType: 'OverUnderOption' | 'RivalOption'; // non null string (OverUnderOption | RivalOption)
  actualStatValue: string;
  powerUp: AppliedPowerUp;
  rebooted: boolean;
  rivalActualStatValue: string;
}

export interface FeaturedSubSection {
  title: string;
  contentType: string;
  key: string;
  sportId: SportId;
  style: 'horizontal' | 'vertical';
}

export interface PlayerGroupValue {
  id: string; // player id
  appearanceIds: string[];
}

export interface FeaturedLobbyFavoritePlayer {
  id: string;
  appearanceIds: string[];
  numberOfActiveLines: number;
  adaptedPlayer: Player;
}
export interface FeaturedLobby {
  airdrops: AirdropOffer[];
  appearances: Appearance[];
  constructedAppearances: ConstructedPickEmOverUnderLineAppearances;
  constructedPromoAppearances: ConstructedPromoAppearance[];
  constructedRivalLines: ConstructedPickEmRivalLine[];
  favoritePlayers: FeaturedLobbyFavoritePlayer[];
  games: Match[];
  hasFeaturedLobbyLoaded: boolean;
  overUnderLines: OverUnderLine[];
  pickemPacks: Pack[];
  playerGroups: {
    [playerGroupKey: string]: PlayerGroupValue[];
  };
  players: {
    [id: string]: Player;
  };
  powerUps: RedeemablePowerUp[];
  rivalLines: RivalLine[];
  sections: FeaturedSubSection[];
  soloGames: SoloGame[];
}

export interface Pack {
  id: string;
  packTitle: string;
  rank: number;
  status: 'active' | 'removed'; // web only
  overUnderLines: {
    lineId: string;
    optionId: string;
  }[];
}

export interface PackLine {
  appearance: ConstructedPickEmOverUnderLineAppearance;
  line: {
    statValue: string;
    displayStat: string;
  };
  option: OverUnderOption;
}
export interface InsuranceFallback {
  currentMaxPayoutMultiplier: string;
  initialMaxPayoutMultiplier: string;
  lossCount: number;
  unboostedPowerUpMultiplier: string;
}

export interface EntrySlip {
  id: string;
  badges?: UserBadge[];
  boostId?: string | null;
  boostPayout?: string;
  cancellationExpiresAt: string | null;
  championPoints: string;
  createdAt?: string;
  currentMaxPayoutMultiplier: string;
  endAt?: string;
  entryCount?: number;
  entryStyleId?: string;
  fee?: string;
  freeEntry: boolean;
  imageUrl?: string;
  initialMaxPayoutMultiplier: string;
  insuranceFallbacks?: InsuranceFallback[];
  insured?: boolean | null;
  payout: string | null;
  payoutAt?: string | null;
  payoutOptionId?: string;
  payoutShifts: string[];
  pickemPoolEntry?: PickEmPoolEntry;
  /**
   * pickemPoolStyleOptionId: Style id for Pick'em Pools V1
   */
  pickemPoolStyleOptionId: string;
  /**
   * pickemPoolStyleId: Style id for Pick'em Pools V2
   */
  pickemPoolStyleId: string;
  powerUp: AppliedPowerUp | null;
  prizeMaxPayout: string;
  rebooted: boolean;
  selections: Selection[];
  settledPickemPoolStyleOptionId: string | null;
  shareId?: string | null;
  shareLink?: string | null;
  startAt?: string;
  status: 'active' | 'live' | 'settled' | 'pending' | 'cancelled' | 'dead' | 'user_cancelled';
  title?: string;
  user?: User;
  username?: string;
}

export interface PickEmPoolEntry {
  id: string;
  payout: string;
  pickemPoolId: string;
  poolEntryCount: number;
}

export interface EntrySlipRivalLine {
  id: string;
  liveEvent: boolean;
  liveEventStats: {
    [appearanceId: string]: string;
  };
  rank: number;
  rivalId: string;
  statValue: string;
  status: string;
}

export interface EntrySlipRivalLines {
  [rivalLineId: string]: EntrySlipRivalLine;
}

export interface RivalOptions {
  [rivalOptionId: string]: RivalOption;
}

export interface Rivals {
  [rivalId: string]: Rival;
}

export interface PickEmEntrySlip {
  selectedOverUnders: SelectedOverUnder[];
  selectedRivals: SelectedRival[];
  error: string | null;
  loaded?: boolean;
  poolV1Style: PoolV1Style;
  poolV2Style: OldPoolV2Style;
  slipType: PickEmSlipType;
  entrySlipLimits: PickEmEntrySlipLimits;
  powerUp: RedeemablePowerUp;
}

export interface PickEmEntrySlipLimit {
  id: string;
  maxFee: string;
  maxAppearanceFee: string;
  sportId: SportId;
  userOverride: boolean;
}

export interface PickEmEntrySlipLimits {
  [sportId: string]: PickEmEntrySlipLimit;
}

// shape used for error messaging in pick'em
export interface EntrySlipLimit {
  userOverride: boolean;
  sportName: string;
  maxFee: string;
}

export interface EntrySlips {
  appearanceIds: string[]; // used for pusher events
  gameIds: string[]; // used for pusher events
  soloGameIds: string[];
  appearances: Appearance[];
  entrySlips: EntrySlip[];
  games: Matches;
  overUnderLines: OverUnderLines;
  overUnderOptions: OverUnderOptions;
  overUnders: OverUnders;
  players: FavoritePlayers;
  rivalLines: EntrySlipRivalLines;
  rivalOptions: RivalOptions;
  rivals: Rivals;
  soloGames: SoloGames;
  liveStatLines: LiveStatLines; // use this to display the scores, because it will be update when things are live
  liveGames: Matches; // use this to display the match info, because it will be update when things are live
  meta: {
    page: number;
    count: number;
    next: number;
    items: number;
  };
  lastUpdated: Date; // used to decide on fetching the latest data
}

export interface SharedEntrySlip {
  active: {
    overUnderLines: OverUnderLines;
    rivalLines: SharedRivalLines;
  };
  appearances: Appearance[];
  entrySlip: EntrySlip;
  games: Matches;
  overUnderOptions: OverUnderOptions;
  overUnders: OverUnders;
  players: FavoritePlayers;
  rivalOptions: RivalOptions;
  rivals: Rivals;
  shared: {
    overUnderLines: OverUnderLines;
    rivalLines: SharedRivalLines;
  };
  soloGames: SoloGames;
}

// Favorite players
export interface FavoritePlayer extends Player {
  favorite?: boolean;
  isInactive?: boolean;
  numberOfActiveLines?: number;
}

export interface FavoritePlayers {
  [playerId: string]: FavoritePlayer;
}

// Rivals
export interface RivalOption {
  id: string;
  appearanceStat: PickEmAppearanceStat;
  rivalLineId: string;
  spread: string;
  type: 'RivalOption';
}

export interface Rival {
  id: string;
  scoringTypeId: string | null;
  title: string;
}

export interface RivalLine {
  id: string;
  liveEvent: boolean;
  liveEventStats: {
    [appearanceId: string]: string;
  };
  options: RivalOption[]; // array length should be 2
  rank: number;
  rival: Rival;
  rivalId: string;
  statValue: string;
  status: string;
}

export interface PlayerCellRivalLine {
  liveEvent: boolean;
  liveEventStat?: string;
}

export interface SharedRivalLine {
  id: string;
  liveEvent: boolean;
  liveEventStats: {
    [appearanceId: string]: string;
  };
  options: RivalOption[]; // array length should be 2
  rank: number;
  rivalId: string;
  statValue: string;
  status: string;
}

export interface SharedRivalLines {
  [rivalLineId: string]: SharedRivalLine;
}

// used in Pick'em lobby for submitting slips
export interface SelectedOption {
  id: string; // optionId
  type: 'OverUnderOption' | 'RivalOption';
  lineId: string;
  playerName: string;
  payoutMultiplier?: number;
}

export interface PayoutOption {
  id: string;
  selectionCount: number;
  minMultiplier: string;
  multiplier: string;
  maxFee: string;
}

export interface PayoutStyle {
  isBoostedMultiplier: boolean;
  maxFee: string;
  maxMultiplier: string;
  minFee: string;
  minSelectionCount: number;
  otherPayouts: OtherPayout[];
  payoutMultiplier: string;
  payoutOptionId?: string;
  selectionCount: number;
  fallbacks?: Fallback[];
  errors?: AppErrorResponse[];
  unboostedPowerUpMultiplier: string;
}

export interface Fallback {
  lossCount: number;
  payoutMultiplier: string;
  unboostedPowerUpMultiplier: string;
}

export interface OtherPayout {
  payoutMultiplier: string;
  selectionCount: number;
  unboostedPowerUpMultiplier: string;
}

export interface PayoutOutcome {
  errors: AppErrorResponse[];
  insurance: PayoutStyle | null;
  lastUpdated: string;
  pickEmPool: NewPoolV2Style;
  selectionCount: number;
  standard: PayoutStyle | null;
}

export interface PoolV1StyleOption {
  id: string;
  entryCount: number;
  fee: string;
  insuredPayoutOption: PayoutOption | null;
  maxEntries: number;
  payoutOption: PayoutOption | null; // null, unless slip pays out
  prizePercentage: string;
  prizes: {
    [key: string]: {
      default: {
        maxPayout: string;
        avgPayout: string;
      };
      insured: {
        maxPayout: string;
        avgPayout: string;
      };
    };
  };
  selectionCount: number;
}
export interface PoolV1Style {
  active: boolean;
  default: boolean;
  fees: string[];
  id: string;
  options: PoolV1StyleOption[];
  prizePercentage: string;
  title: string;
}

export interface OldPoolV2Style {
  id: string;
  active: boolean;
  baseFee: string;
  default: boolean;
  fees: string[];
  maxFee: string;
  prizePerPoint: string;
  rake: string;
  title: string;
  totalFees: string;
}

export interface NewPoolV2Style {
  entryRole: string;
  maxFee: string;
  rake: string;
  styleId: string;
  totalFees: string;
}

export interface PoolV1Styles {
  [key: string]: PoolV1Style;
}

export interface PoolV2Styles {
  [key: string]: OldPoolV2Style;
}
export interface UserPickEmPoolsLeaderboardEntrySlip {
  appearances: Appearance[];
  entrySlips: EntrySlip[];
  games: Matches;
  overUnderLines: OverUnderLines;
  overUnderOptions: OverUnderOptions;
  overUnders: OverUnders;
  players: FavoritePlayers;
  rivalLines: EntrySlipRivalLines;
  rivalOptions: RivalOptions;
  rivals: Rivals;
  soloGames: SoloGames;
}

export interface PickEmEntries {
  leaderboard: EntrySlips;
  live: EntrySlips;
  poolV1Styles: PoolV1Styles;
  poolV2Styles: PoolV2Styles;
  selected: PickEmEntrySlip;
  settled: EntrySlips;
  payoutOutcome: PayoutOutcome;
  prevPayoutOutcome: PayoutOutcome;
  userLeaderboard: EntrySlips;
}

export type EntrySlipState = 'leaderboard' | 'userLeaderboard' | 'live' | 'settled';
export type InfoModalContentToShow = (
  | 'insurance'
  | 'payoutShifting'
  | 'boost'
  | 'rescuesStandard'
  | 'rescuesChampions'
  | 'payoutMatrix'
)[];

/**
 * Responses
 * ***********************************************
 */

// Get PickEm lines response
export interface OverUnderOptionResponse {
  id: string;
  choice: 'higher' | 'lower';
  choice_display: string;
  over_under_line_id: string;
  payout_multiplier: string;
  type: 'OverUnderOption';
}

export interface PickEmAppearanceStatResponse {
  id: string;
  appearance_id: string;
  display_stat: string;
  graded_by: 'high_score' | 'low_score';
  pickem_stat_id: string;
  stat: string; // this is probably an enum
}

export interface OverUnderResponse {
  id: string;
  scoring_type_id: string | null;
  title: string;
  // appearance_stat is here, because it's just one stat
  // no need to have the same appearance stat on each option
  appearance_stat: PickEmAppearanceStatResponse;
  boost: BoostResponse | null;
  option_priority: 'higher' | 'lower' | 'none';
}

export interface BoostResponse {
  id: string;
  boost_type: 'payout_booster' | 'special_line' | 'sweepstakes' | 'discount';
  color: string | null;
  coming_soon_enabled: boolean;
  contentful_info_id: string | null;
  description: string;
  display_title: string;
  expired_at: string | null;
  icon: string;
  payout_style_id: string;
  payout_type: string;
  text_color: string;
}

export interface AirdropOffer {
  airdropId: string;
  amount: string;
  contentfulInfoId: string;
  daysForClawback: string;
  description: string;
  expiresAt: string | null;
  id: string;
  startsAt: string;
  title: string;
}

export interface AirdropOffersResponse {
  airdrop_id: string;
  amount: string;
  contentful_info_id: string;
  days_for_clawback: string;
  description: string;
  expires_at: string | null;
  id: string;
  starts_at: string;
  title: string;
}

export interface OverUnderLineResponse {
  id: string;
  expires_at: string | null;
  live_event: boolean;
  live_event_stat: string | null;
  options?: OverUnderOptionResponse[];
  over_under?: OverUnderResponse;
  over_under_id: string;
  rank: number;
  stat_value: string;
  status: 'pending' | 'closed' | 'active' | 'removed'; // 'removed' is not an api thing, but a web thing
}

export interface SoloGameResponse {
  id: number;
  competition_id: string | null;
  manually_created: boolean;
  match_progress: string | null;
  period: number;
  scheduled_at: string;
  score: string | null;
  sport_id: SportId;
  status: MatchStatus;
  sport_tournament_round_id: number | null;
  title: string;
  type: 'SoloGame';
}

export interface OverUnderLinesResponse {
  over_under_lines: OverUnderLineResponse[];
  appearances: AppearancesResponse['appearances'];
  games: MatchesResponse['matches'];
  players: PlayersResponse['players'];
  solo_games: SoloGameResponse[];
}

export interface FeaturedOverUnderLinesResponse {
  appearances: AppearancesResponse['appearances'];
  boosts: BoostResponse[];
  games: MatchesResponse['matches'];
  over_under_lines: OverUnderLineResponse[];
  players: PlayersResponse['players'];
  solo_games: SoloGameResponse[];
}

export interface OverUnderLineSwapResponse {
  old_id: string;
  old_stat: string;
  new_id: string;
  new_stat: string;
  options: OverUnderOptionResponse[];
  multiplier_swap: boolean;
  old_line_options: OverUnderOptionResponse[];
}

/**
 * Featured Lobby
 */
export interface FeaturedSectionResponse {
  title: string;
  content_type: string;
  key: string;
  style: 'horizontal' | 'vertical';
  sport_id: SportId;
}

interface PlayerGroupValueResponse {
  id: string; // player id
  appearance_ids: string[];
}

export interface FeaturedLobbyResponse {
  airdrop_offers: AirdropOffersResponse[];
  appearances: AppearanceResponse[];
  boosts: BoostResponse[];
  games: MatchResponse[];
  over_under_lines: OverUnderLineResponse[];
  pickem_packs: PacksResponseItem[];
  player_groups: {
    [playerGroupKey: string]: PlayerGroupValueResponse[];
  };
  power_ups: RedeemablePowerUpResponse[];
  players: PlayerResponse[];
  rival_lines: RivalLineResponse[];
  sections: FeaturedSectionResponse[];
  solo_games: SoloGameResponse[];
}

export interface PacksResponseItem {
  id: string;
  pack_title: string;
  rank: number;
  over_under_lines: {
    line_id: string;
    option_id: string;
  }[];
}

// Active and settled entry slips
export interface SelectionResponse {
  id: string;
  in_play: boolean;
  result: PickEmResult;
  actual_stat_value: string;
  rival_actual_stat_value: string;
  option_id: string;
  option_type: 'OverUnderOption' | 'RivalOption'; // non null string (OverUnderOption | RivalOption)
  power_up: AppliedPowerUpResponse;
  rebooted: boolean;
}

// Active and settled entry slips
export interface InsuranceFallbackResponse {
  current_max_payout_multiplier: string;
  initial_max_payout_multiplier: string;
  loss_count: number;
  unboosted_power_up_multiplier: string;
}

export interface EntrySlipResponse {
  id: string;
  badges?: UserBadgeResponse[];
  boost_id: string;
  boost_payout: string;
  cancellation_expires_at: string | null;
  champion_points: string;
  created_at?: string;
  current_max_payout_multiplier: string;
  end_at?: string;
  entry_count?: number;
  entry_style_id?: string;
  fee: string;
  free_entry: boolean;
  image_url?: string;
  initial_max_payout_multiplier: string;
  insurance_fallbacks?: InsuranceFallbackResponse[];
  insured: boolean | null;
  payout: string | null;
  payout_at?: string | null;
  payout_option_id: string;
  payout_shifts: string[];
  pickem_pool_entry?: PickEmPoolEntryResponse; // results only
  pickem_pool_style_id: string; // pools v2
  pickem_pool_style_option_id: string;
  power_up: AppliedPowerUpResponse | null;
  prize_max_payout: string; // pools v1
  rebooted: boolean;
  selections: SelectionResponse[];
  settled_pickem_pool_style_option_id: string | null;
  share_id?: string;
  share_link?: string;
  start_at?: string;
  status: 'active' | 'live' | 'settled' | 'pending' | 'cancelled' | 'dead';
  title?: string;
  user?: NotCurrentUserResponse;
  username?: string;
}

export interface PickEmPoolEntryResponse {
  id: string;
  payout: string;
  pickem_pool_id: string;
  pool_entry_count: number;
}

export interface EntrySlipRivalLineResponse {
  id: string;
  live_event: boolean;
  live_event_stats: {
    [appearanceId: string]: string;
  };
  rank: number;
  rival_id: string;
  stat_value: string;
  status: string;
}

export interface EntrySlipsResponse {
  data: {
    appearances: AppearancesResponse['appearances'];
    entry_slips: EntrySlipResponse[];
    games: MatchesResponse['matches'];
    over_under_lines: OverUnderLineResponse[];
    over_under_options: OverUnderOptionResponse[];
    over_unders: OverUnderResponse[];
    players: PlayersResponse['players'];
    rival_lines: EntrySlipRivalLineResponse[];
    rival_options: RivalOptionResponse[];
    rivals: RivalResponse[];
    solo_games: SoloGameResponse[];
  };
  meta: {
    page: number;
    count: number;
    next: number;
    items: number;
  };
}

// Favorite players
export interface FavoritePlayersResponse {
  favorite_players: PlayerResponse[];
}

// Rival lines
export interface RivalOptionResponse {
  id: string;
  // appearance_stat is here because each option, is a player
  // and needs a stat
  appearance_stat: PickEmAppearanceStatResponse;
  rival_line_id: string;
  spread: string;
  type: 'RivalOption';
}

export interface RivalResponse {
  id: string;
  scoring_type_id: string | null;
  title: string;
}

export interface RivalLineResponse {
  id: string;
  live_event: boolean;
  live_event_stats: {
    [appearanceId: string]: string;
  };
  options: RivalOptionResponse[]; // array length should be 2
  rank: number;
  rival: RivalResponse;
  rival_id: string;
  stat_value: string;
  status: string;
}

export interface RivalLinesResponse {
  rival_lines: RivalLineResponse[];
  appearances: AppearancesResponse['appearances'];
  games: MatchesResponse['matches'];
  players: PlayersResponse['players'];
  solo_games: SoloGameResponse[];
}

export interface ShareLinkResponse {
  share_link: {
    url: string;
    image_url: string;
    display_text: string;
  };
}

export interface SharedEntrySlipResponse {
  active: {
    over_under_lines: OverUnderLineResponse[];
    rival_lines: RivalLineResponse[];
  };
  appearances: AppearancesResponse['appearances'];
  entry_slip: EntrySlipResponse;
  games: MatchesResponse['matches'];
  over_under_options: OverUnderOptionResponse[];
  over_unders: OverUnderResponse[];
  players: PlayersResponse['players'];
  rival_options: RivalOptionResponse[];
  rivals: RivalResponse[];
  shared: {
    over_under_lines: OverUnderLineResponse[];
    rival_lines: RivalLineResponse[];
  };
  solo_games: SoloGameResponse[];
}

export interface PickEmEntrySlipLimitResponse {
  id: string;
  max_fee: string;
  max_appearance_fee: string;
  sport_id: SportId;
  user_override: boolean;
}

export interface PickEmEntrySlipLimitsResponse {
  limits: PickEmEntrySlipLimitResponse[];
}

/**
 * Payout Outcome response
 */

export interface FallbackResponse {
  loss_count: number;
  payout_multiplier: string;
  unboosted_power_up_multiplier: string;
}

export interface OtherPayoutResponse {
  payout_multiplier: string;
  selection_count: number;
  unboosted_power_up_multiplier: string;
}

export interface PayoutOutcomeResponse {
  selection_count: number;
  /**
   * `standard` is expected to be null when the user has > 5 selections
   */
  standard: {
    is_boosted_multiplier: boolean;
    max_fee: string;
    max_multiplier: string;
    min_fee: string;
    min_selection_count: number;
    other_payouts: OtherPayoutResponse[];
    payout_multiplier: string;
    payout_option_id?: string;
    errors: ApiErrorDataResponse[];
    unboosted_power_up_multiplier: string;
  } | null;
  insurance: {
    is_boosted_multiplier: boolean;
    fallbacks: FallbackResponse[];
    max_fee: string;
    max_multiplier: string;
    min_fee: string;
    min_selection_count: number;
    other_payouts: OtherPayoutResponse[];
    payout_multiplier: string;
    payout_option_id?: string;
    errors: ApiErrorDataResponse[];
    unboosted_power_up_multiplier: string;
  };
  pickem_pools: {
    entry_role: string;
    max_fee: string;
    rake: string;
    style_id: string;
    total_fees: string;
  }[];
  errors: ApiErrorDataResponse[];
}

/**
 * PayoutStyles response
 */
export interface PayoutOptionResponse {
  id: string;
  selection_count: number;
  min_multiplier: string;
  multiplier: string;
  max_fee: string;
}

export interface PoolV1StyleOptionResponse {
  id: string;
  entry_count: number;
  fee: string;
  insured_payout_option: PayoutOptionResponse | null;
  max_entries: number;
  payout_option: PayoutOptionResponse | null; // null, unless slip pays out
  prize_percentage: string;
  prizes: {
    [key: string]: {
      default: {
        max_payout: string;
        avg_payout: string;
      };
      insured: {
        max_payout: string;
        avg_payout: string;
      };
    };
  };
  selection_count: number;
}

export interface PoolV2StyleResponse {
  id: string;
  active: boolean;
  base_fee: string;
  default: boolean;
  fees: string[];
  max_fee: string;
  prize_per_point: string;
  rake: string;
  title: string;
  total_fees: string;
}

export interface PoolV1StyleResponse {
  active: boolean;
  default: boolean;
  fees: string[];
  id: string;
  options: PoolV1StyleOptionResponse[];
  prize_percentage: string;
  title: string;
}

export interface PoolV1StylesResponse {
  pickem_pool_styles: PoolV1StyleResponse[];
}

export interface PoolV2StylesResponse {
  pickem_pool_styles: PoolV2StyleResponse[];
}

export interface UserPickEmPoolsLeaderboardEntrySlipResponse {
  data: {
    appearances: AppearancesResponse['appearances'];
    entry_slips: EntrySlipResponse[];
    games: MatchesResponse['matches'];
    over_under_lines: OverUnderLineResponse[];
    over_under_options: OverUnderOptionResponse[];
    over_unders: OverUnderResponse[];
    players: PlayersResponse['players'];
    rival_lines: RivalLineResponse[];
    rival_options: RivalOptionResponse[];
    rivals: RivalResponse[];
    solo_games: SoloGameResponse[];
  };
}
